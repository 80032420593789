/*eslint-disable*/ module.exports = {
  messages: {
    '"Unavailable"': "“No disponible”",
    '"unavailable"': '"unavailable"',
    "(between the {0} and {1})": ["(entre el ", ["0"], " y ", ["1"], ")"],
    "1. Copy the following link.": "1. Copie el url generado.",
    "2. Go to your google calendar and click on the settings.":
      "2. Ingrese a Google Calendar, click en configuraciones.",
    '3. In the left menu, click on "Add calendar" and then on "From URL".':
      '3. En el menu izquierdo, click en "Agregar calendario" y luego en "Desde url".',
    "3. In the left menu, click on Add calendar and then on From URL.":
      '3. En el menu izquierdo, click en "Agregar calendario" y luego en "Desde url".',
    '4. Paste the link and click on "Add calendar".':
      '4. Pegar el url y click en "Agregar calendario".',
    "4. Paste the link and click on Add calendar.":
      '4. Pegar el url y click en "Agregar calendario".',
    "A lease will be created based on the form.":
      "Se creará un arrendamiento en base al formulario",
    "A lease will be created based on the requirement":
      "Se creara un arriendo en base al requerimiento",
    "A new machine will be added to the lease list.":
      "Se agregará una nueva máquina al listado de arriendo.",
    "A new machine will be added to the requirement list.":
      "Se agregará una nueva máquina al listado de requerimientos.",
    "A new purchase order will be created based on the form and it will be stored in our DB, which you can download.":
      "Se creará una nueva orden de compra basada en el formulario y se almacenará en nuestra base de datos, que puede descargar.",
    "A new purchase order will be created based on the form, it will be stored in our DB and automatically sent to the supplier's mail.":
      "Se creará una nueva orden de compra en base al formulario, se almacenará en nuestra BD y se enviará automáticamente al correo del proveedor.",
    "A new purchase order will be created based on the form, you can send it to the supplier.":
      "Se creará una nueva cotización, la cual podrás enviar al proveedor.",
    "A new quote will be created based on the form and it will be stored in our DB, which you can download.":
      "Se creará una nueva cotización basada en el formulario y se almacenará en nuestra base de datos, que puede descargar.",
    "A new quote will be created based on the form, it will be stored in our DB and automatically sent to the client's email.":
      "Se creará una nueva cotización basada en el formulario, se almacenará en nuestra base de datos y se enviará automáticamente al correo electrónico del cliente.",
    "A new quote will be created, you can send it to the client.":
      "Se creará una nueva cotización, la cual podrás enviar al cliente.",
    "A new task is added to the list of tasks for this requirement":
      "Se agrega una nueva tarea a la lista de tareas para este requisito",
    Accepted: "Aceptada",
    "Accepted in plural": "Aceptadas",
    "According to the whole order": "Respecto al arriendo completo",
    Accumulated: "Acumulado",
    Actions: "Acciones",
    "Activate rent": "Activar renta",
    Active: "Activo",
    Add: "Agregar",
    "Add a budget": "Agregar cotización",
    "Add a buy order": "Agregar orden de compra",
    "Add a machine": "Agregar una máquina",
    "Add brand": "Agregar marca",
    "Add comment": "Añadir comentario",
    "Add commertial date": "Agregar fecha comercial",
    "Add destination": "Agregar destino",
    "Add documents": "Agregar documentos",
    "Add equipment": "Añadir maquinaria",
    "Add gps": "Añadir GPS",
    "Add item": "Agregar item",
    "Add lease": "Agregar arriendo",
    "Add machine": "Agregar máquina",
    "Add machines available for sale":
      "Añadir máquinas disponibles para la venta",
    "Add model": "Añadir modelo",
    "Add movement": "Agregar movimiento",
    "Add new": "Agregar",
    "Add new branch": "Agregar nueva sucursal",
    "Add new budget": "Agregar nueva cotización",
    "Add new category": "Agregar nueva categoria",
    "Add new condition": "Añadir nueva condición",
    "Add new customer": "Añadir nueva persona contacto",
    "Add new document": "Add new document",
    "Add new invoice": "Agregar factura",
    "Add new machine": "Añadir nueva máquina",
    "Add new organization": "Añadir nueva organización",
    "Add new payment": "Agregar pago",
    "Add new product": "Añadir nuevo producto",
    "Add new purchase order": "Agregar orden de compra",
    "Add new rate": "Añadir nueva tarifa",
    "Add new refund": "Registrar reembolso",
    "Add new subcategory": "Add new subcategory",
    "Add new supplier": "Añadir nuevo proveedor",
    "Add new supplier user": "Agregar usuario",
    "Add photographs": "Agregar fotografías",
    "Add product": "Agregar producto",
    "Add requirement": "Agregar requerimiento",
    "Add technical service": "Agregar servicio técnico",
    "Additional discount": "Retención Adicional",
    "Additional tax": "Retención Adicional",
    Address: "Dirección",
    "Address company": "Dirección de empresa",
    "Address reference": "Dirección de referencia",
    Addresses: "Direcciones",
    "Aditional discount": "Retención Adicional",
    "Aditional receiver": "Receptor adicional",
    "Administration machines": "Equipos en administración",
    Agregar: "Agregar",
    "Agregar producto": "Agregar producto",
    Agricultural: "Agricultural",
    All: "Todo",
    "All the monetary amounts reflected in this dashboard are in USD":
      "Todos los montos monetarios reflejados en este dashboard están reflejados en USD",
    "All the monetary amounts reflected in this dashboard are net values ​​and in local currency":
      "Todos los montos monetarios reflejados en este dashboard son valores netos y en moneda local",
    Amount: "Cantidad",
    "Amount of days": "Cantidad de días",
    "An extension must start the same of the next day of the last period":
      "Una extension debe iniciarse el mismo día o el siguiente del último periodo.",
    "An extension of the selected machines will be created":
      "Una extensión de las máquinas seleccionadas será creada",
    April: "Abril",
    "Archive lead": "Archive lead",
    "Are you sure you want reopen order?":
      "Está seguro de que desea reabrir la orden?",
    "Are you sure you want to activate the automatic search for":
      "Estás seguro de que quieres activar la búsqueda automática para",
    "Are you sure you want to activate the automatic supplier search for this machine?":
      "¿Estás seguro de querer activar la búsqueda automática para esta máquina?",
    "Are you sure you want to delete this item?":
      "¿Estás seguro que quieres eliminar este elemento?",
    "Are you sure you want to finish the lease for this machine?":
      "¿Estás seguro de querer finalizar este arriendo?",
    "Are you sure you want to reactivate the lease for this machine?":
      "¿Estás seguro de querer activar nuevamente este arriendo?",
    "Are you sure you want to save and send this lead?":
      "Está seguro de que desea guardar y enviar la cotización?",
    "Are you sure you want to save this lead and create the budget?":
      "Está seguro de que desea guardar este requerimiento y crear la cotización?",
    "Are you sure you want to save this lead and create the buy order?":
      "Está seguro de que desea guardar este requerimiento y crear una orden de compra?",
    "Are you sure you want to save this lead and create the lease?":
      "¿Está seguro de que desea guardar y crear un arrendamiento?",
    "Are you sure you want to save this lead and send the buy order?":
      "Está seguro de que desea guardar este requerimiento y enviar una orden de compra?",
    "Are you sure you want to save this lead?":
      "Está seguro de que desea guardar este requerimiento?",
    "Are you sure you want to send a reminder?":
      "¿Está seguro de que desea enviar un recordatorio?",
    "Are you sure you want to send the budget":
      "Seguro que quieres enviar esta cotización",
    "Are you sure you want to send the invoice":
      "Seguro que quieres enviar la factura",
    "Are you sure you want to send the payment reminder":
      "Seguro que quieres enviar el recordatorio de pago",
    "Are you sure you want to send the purchase order":
      "Seguro que quieres enviar esta órden de compra",
    "Are you sure you want to send this": "Seguro que quieres enviar esto",
    "Arriendo simplificado": "Arriendo simplificado",
    "Assignated Leads": "Req. Asignados",
    "Assigned Req": "Requerimientos asignados",
    "Assigned supplier": "Proveedor asignado",
    "Assignments completed": "Assignments completed",
    "Attach invoice": "Adjuntar factura",
    "Attach voucher": "Adjuntar comprobante",
    Attachment: "Adjunto",
    Attachments: "Archivos",
    Attention: "Cuidado",
    August: "Agosto",
    Author: "Autor",
    "Automatic budget": "Cotizado automáticamente",
    "Automatic supplier request is active":
      "La búsqueda automática de proveedor está activa para esta máquina",
    "Automatic supplier search": "Búsqueda de proveedor automática",
    Availability: "Disponibilidad",
    "Availability notes": "Notas de disponibilidad",
    Available: "Disponible",
    "Available for lease": "Disponible para arriendo",
    "Available for sale": "Disponible para venta",
    Back: "Atrás",
    Base: "Base",
    Battery: "Batería",
    Billing: "Facturación",
    Bills: "Gastos",
    Binnacle: "Bitácora",
    Branch: "Branch",
    Branches: "Sucursales",
    Brand: "Marca",
    Brasil: "Brasil",
    Budget: "Cotización",
    "Budget Author": "Autor de la cotización",
    "Budget creation": "Creación de cotización",
    "Budget has invoices":
      "No es posible cambiar el estado de esta cotización debido a que tiene facturas asociadas a la misma.",
    "Budget has no details": "La cotización no tiene detalles",
    "Budget sent": "Cotización enviada",
    "Budget tax": "Tasa de cotizado",
    Budgeted: "Cotizado",
    Budgets: "Cotizaciones",
    "Budgets sent": "Cotizaciones emitidas",
    Business: "Negocios",
    "Business Name": "Razón Social",
    "Business closure": "Cierre comercial",
    "Business name": "Razón social",
    "Business won": "Negocios ganados",
    Buy: "Compra",
    "Buy Quote": "Órdenes de compra",
    "Buy orders": "Órdenes de compra",
    "Buy payments": "Pagos de compra",
    "By Bill": "Por facturar",
    "By sharing, anyone with the link will be able to see the location of your machines until you decide to stop sharing it":
      "Al compartir, cualquier persona con el link podrá ver la localización de tus máquinas hasta que tu decidas dejar de compartirla",
    COMPANY: "EMPRESA",
    "CREATE LEASES": "crea los arriendos",
    "Calendar view": "Vista Calendario",
    Call: "Llamada",
    Cancel: "Cancelar",
    "Cancel supplier request": "Cancelar búsqueda de proveedor",
    "Car plate": "Placa camión",
    Care: "Atención",
    Careful: "Careful",
    Carrier: "Transportista",
    "Carrier already exists with this DNI":
      "El transportista ya existe con este DNI",
    "Carrier info": "Datos del transportista",
    "Cash flow": "Flujo de caja",
    Categories: "Categorías",
    Category: "Categoría",
    "Category and year": "Categoría y año",
    "Change supplier": "Cambiar empresa",
    Channel: "Canal",
    Characteristics: "Caracteristicas",
    Chile: "Chile",
    Cities: "Cities",
    City: "Comuna",
    "Clean filter": "Limpiar filtros",
    "Clean filters": "Limpiar filtros",
    "Clear filters": "Limpiar filtros",
    "Click here to mark this task as completed":
      "Haz click aquí para marcar esta tarea como completada",
    "Click here to show all points":
      "Haz clic aquí para mostrar todos los puntos",
    "Click to upload or drag and drop": "Click to upload or drag and drop",
    Client: "Persona contacto",
    "Client & phone": "Persona contacto y teléfono",
    "Client choose another supplier": "Cliente escogió un proveedor diferente",
    "Client didnt answer": "Cliente no respondió",
    Clients: "Clientes",
    Close: "Cerrar",
    "Close date": "Fecha de cierre",
    "Close lease": "Cerrar arriendo",
    "Close order": "Cerrar arriendo",
    "Close rate": "Tasa de cierre",
    Closed: "Cerrado",
    "Closed leases": "Tasa de cierre de arriendo",
    "Closed sales": "Tasa de cierre venta",
    "Closing rate": "Tasa de cierre",
    Collected: "Recolectado",
    "Comercial Control": "Control Comercial",
    Comments: "Comentarios",
    "Commercial address": "Dirección comercial",
    "Commercial control": "Control comercial",
    "Commercial date": "Fecha comercial",
    Company: "Empresa",
    "Company & phone": "Empresa y teléfono",
    "Company and person in charge to whom the lease is made.":
      "Empresa y responsable a quien se le realiza el arriendo.",
    "Company info": "Datos de la empresa",
    "Company name": "Razón social",
    "Compared to the previous month": "Con respecto al mes anterior",
    Completed: "Completada",
    "Completed tasks": "Tareas completadas",
    "Concluciones del Técnico": "Concluciones del Técnico",
    Conditions: "Condiciones",
    Confirm: "Confirmar",
    "Confirm budget": "Confirmar cotización",
    "Confirm purchase order": "Confirmar órden de compra",
    Confirmation: "Confirmación",
    Construction: "Construcción",
    Consumption: "Consumo",
    Contact: "Contacto",
    "Contact info": "Datos de contacto",
    "Contact name": "Nombre de contacto",
    "Contact on {0}": ["Contactar el ", ["0"]],
    "Contact on {0} between {start} - {end}": [
      "Contactar el ",
      ["0"],
      " entre ",
      ["start"],
      " - ",
      ["end"],
    ],
    "Contact person": "Persona de contacto",
    "Contact today between {start} - {end}": [
      "Contactar hoy entre ",
      ["start"],
      " - ",
      ["end"],
    ],
    Contacted: "Contactado",
    Contacts: "Contactos",
    "Convert to invoice": "Convertir a factura",
    "Copy link": "Copiar link",
    "Corresponds to": "Corresponde a",
    "Corresponds to invoice": "Corresponde a factura",
    Costs: "Costos",
    Cotización: "Cotización",
    Counters: "Contadores",
    Country: "País",
    Coupon: "Cupón",
    "Crear renta": "Crear renta",
    Create: "Crear",
    "Create Leasing": "Crear Arriendo",
    "Create a task": "Crear una tarea",
    "Create an extension": "Crear extensión",
    "Create brand": "Create brand",
    "Create budget": "Crear cotización",
    "Create buy order": "Crear orden de compra",
    "Create extension": "Crear extensión",
    "Create lease": "Crear arriendo",
    "Create new customer": "Crear nueva persona contacto",
    "Create new lease": "Crear nuevo arriendo",
    "Create purchase order": "Crear órdenes de compra",
    "Create task": "Crear tarea",
    Credit: "Crédito",
    "Credit note": "Nota de crédito",
    "Credit note saved!": "¡Nota de crédito guardada!",
    "Credit notes": "Notas de crédito",
    "Credit pay": "Pago a crédito",
    "Cumulative total": "Total acumulado",
    Currency: "Divisa",
    "Current month": "Mes actual",
    "Current occupancy rate": "Tasa de ocupación actual",
    "Current order": "Orden actual",
    "Current rent": "Arriendo actual",
    "Current status": "Estado actual",
    "Current week": "Semana actual",
    "Current year": "Año actual",
    "Currently sharing with": "Actualmente compartiendo con",
    Customer: "Cliente",
    "Customer already exists":
      "El nombre o correo electrónico está en uso, por favor intente con otro y vuelva a intentarlo.",
    "Customer described issue": "Falla descrita por el cliente",
    "Customer interested on automatic budget":
      "Cliente interesado en cotización automática",
    Customers: "Personas contacto",
    DNI: "RUT",
    Daily: "Diario",
    "Daily price": "Precio diario",
    Dashboard: "Dashboard",
    "Dashboard settings": "Dashboard settings",
    Data: "Datos",
    Date: "Fecha",
    Dates: "Fechas",
    Day: "Día",
    Days: "Días",
    "Days sum": "Sumatoria de días",
    Deadline: "Fecha límite",
    Debt: "Deuda",
    December: "Diciembre",
    Delay: "Días de anticipación",
    Delete: "Eliminar",
    "Delete budget": "Eliminar cotización",
    "Delete category": "Eliminar categoria",
    "Delete customer": "Eliminar persona contacto",
    "Delete invoice": "Eliminar factura",
    "Delete lead": "Eliminar prospecto",
    "Delete machine": "Eliminar máquina",
    "Delete order": "Eliminar orden",
    "Delete organization": "Eliminar organización",
    "Delete supplier": "Eliminar proveedor",
    Delivery: "Transporte",
    "Delivery Pending": "Delivery Pending",
    "Delivery address": "Dirección de entrega",
    "Delivery date": "Fecha de entrega",
    "Delivery driver": "Conductor de entrega",
    "Delivery pending": "Por entregar",
    Delivery_movement: "Entrega",
    Description: "Descripción",
    Destination: "Destino",
    "Detail of the machines required by the client.":
      "Detalle de las máquinas solicitadas por el cliente.",
    "Dias rentados": "Dias rentados",
    Disabled: "Deshabilitado",
    Discount: "Descuento",
    "Discount information": "Información del descuento",
    Distance: "Distancia",
    Dni: "Rut",
    "Do not send": "No enviar",
    "Do you want to remove it? The information will be permanently removed from our servers. This action can not be undone.":
      "¿Desea eliminar esto? toda la informacion será eliminada permanentemente de nuestros servidores. Esta acción no se puede deshacer.",
    "Do you want to remove {itemToDelete}? The information will be permanently removed from our servers. This action can not be undone.":
      [
        "¿Desea eliminar ",
        ["itemToDelete"],
        "? toda la informacion será eliminada permanentemente de nuestros servidores. Esta acción no se puede deshacer.",
      ],
    "Do you want to send the buy order to the suppliers mail? Even if you decide not to send it it will be available for you to download it and send it manually whenever you want to.":
      "¿Deseas enviar la orden de compra al correo del proveedor? Si decides no enviar, igualmente la podrás descargar y enviar manualmente cuando lo estimes conveniente.",
    "Do you want to send the quotation to the customers mail? Even if you decide not to send it it will be available for you to download it and send it manually whenever you want to.":
      "¿Deseas enviar la cotización al correo de la persona contacto? Si decides no enviar, igualmente la podrás descargar y enviar manualmente cuando lo estimes conveniente.",
    "Document attach": "Adjuntar documento",
    "Document to sent": "Documento a enviar",
    "Document type": "Tipo de documento",
    Documents: "Documentos",
    Download: "Descargar",
    "Download business summary": "Descargar cierre comercial",
    Driver: "Conductor",
    "Drop the files here ...": "Suelta los archivos aquí ...",
    Duplicate: "Duplicar",
    Duration: "Duración",
    "Duration:": "Duration:",
    "Economic Activity": "Economic Activity",
    "Economic activity": "Giro",
    Edit: "Editar",
    "Edit Leasing": "Editar Arriendo",
    "Edit branch": "Editar sucursal",
    "Edit commercial date": "Editar fecha comercial",
    "Edit condition": "Editar condición",
    "Edit extension": "Editar extensión",
    "Edit invoice": "Editar factura",
    "Edit lead": "Editar requerimiento",
    "Edit machine": "Editar máquina",
    "Edit payment": "Editar pago",
    "Edit rate": "Editar tarifa",
    "Edit refund": "Editar reembolso",
    "Edit task": "Editar tarea",
    Email: "Email",
    "Email:": "Email:",
    Enabled: "Habilitado",
    End: "Fin",
    "End date": "Fecha de término",
    "End lease": "Terminar arriendo",
    "Enter all the machines included in this lease":
      "Ingresa todas las máquinas incluidas en este arriendo",
    Entrada: "Entrada",
    "Equal than last month": "Igual al mes anterior",
    Equipment: "Equipo",
    "Equipment available from": "Máquinaria disponible desde",
    "Equipment info": "Información del equipo",
    "Equipment to lease": "Maquinaria a arrendar",
    "Equipment wasnt located": "No se localizó el equipo",
    Equipos: "Equipos",
    Error: "Error",
    "Este proceso puede durar unos minutos":
      "Este proceso puede durar unos minutos",
    "Estimated duration": "Duración estimada",
    "Estos campos generan una condicion, la cual evalua si un requerimiento es apto o no para una cotizacion automatica":
      "Estos campos generan una condicion, la cual evalua si un requerimiento es apto o no para una cotizacion automatica",
    "Ex: Moneda 1202, Santiago": "Ex: Moneda 1202, Santiago",
    Expirated: "Vencido",
    Expiration: "Vencimiento",
    Expired: "Vencido",
    "Expires in": "Vence en",
    "Expires today": "Vence hoy",
    Extension: "Extensión",
    "Extra information": "Información extra",
    Extras: "Extras",
    Facebook: "Facebook",
    "Falla descrita por el cliente": "Falla descrita por el cliente",
    February: "Febrero",
    Fee: "Tarifa",
    "Field training": "Inducción en terreno",
    "Fill with debt": "Autocompletar con deuda pendiente",
    "Filling in all possible fields will help you keep better control of your organization.":
      "Rellenar todos los campos posibles te ayudará a manetener un mejor control de tu organización.",
    "Filling in all the possible fields will help you to carry out a better control of your organization.":
      "Rellenar todos los campos posibles te ayudará a manetener un mejor control de tu organización.",
    Filter: "Filtro",
    "Filter columns": "Filtrar columnas",
    "Filter documents": "Filter documents",
    "Filter in the form on the left to have the data of the selected machines.":
      "Filtre en el formulario de la izquierda para tener los datos de las máquinas seleccionadas.",
    Filters: "Filtros",
    "Finish rent": "Finalizar renta",
    Finished: "Finalizado",
    "First name": "Nombre",
    "Floor price": "Precio desde",
    Folio: "Folio",
    "For this action, you need to fill the following fields: {errors}": [
      "Para esta acción debes rellenar los siguientes campos: ",
      ["errors"],
    ],
    From: "Desde",
    "From all of the machines included in this lease":
      "De toda la maquinaria incluida en este arriendo",
    "Full name": "Nombre y apellido",
    GPS: "GPS",
    Gantt: "Gantt",
    General: "General",
    "General statistics": "Estadísticas generales",
    "Generate web content": "Generar contenido web",
    "Generated Budgets": "Cotizaciones emitidas",
    "Generic Sale": "Venta genérica",
    "Geo fees": "Tarifas geolocalizadas",
    Geolocation: "Geolocalización",
    "Global dashboard": "Global dashboard",
    "Go back home": "Regresar al Home",
    "Go to lead": "Ir al requerimiento",
    "Go to requirements": "Ir a requerimientos",
    "Go to schedule": "Ir a cronograma",
    "Go to tasks": "Ir a tareas",
    "Go to the lead detail": "Ir al detalle del requerimiento",
    "Go to the order": "Ir al arriendo",
    "Go to the requirement": "Ir al requerimiento",
    "Gps model": "Modelo GPS",
    "Gross amount": "Monto bruto",
    HTML: "HTML",
    "Haga clic para cargar o arrastrar y soltar":
      "Haga clic para cargar o arrastrar y soltar",
    Hello: "Hello",
    "Here you can see all the docs generated":
      "Aquí puedes ver todos los documentos generados",
    "Here you can see all the machines in this requirement":
      "Aqui puedes ver todas las máquinas de este requerimiento",
    "Here you will find all the requirement data":
      "Aquí conseguirás los datos del requerimiento",
    "Here you will find the leases registered for this equipment.":
      "Aqui encontrarás los arriendos registrados para este equipo.",
    "Hide archived": "Hide archived",
    "Hide completed": "Ocultar completadas",
    "Hide list": "Ocultar lista",
    "Hide tasks": "Ocultar tareas",
    Historical: "Histórico",
    "Historical occupancy rate": "Tasa de ocupación histórica",
    Home: "Inicio",
    Horometer: "Horometro",
    Hourly: "Hora",
    "Hourly price": "Precio hora",
    ID: "ID",
    "ID Lease": "ID Renta",
    "ID Renta": "ID Renta",
    "ID Req": "ID Req",
    IVA: "IVA",
    "If you need to lease a machine on another date, you must create a new lease.":
      "Si necesitas arrendar una máquina en otra fecha, debes crear un nuevo arriendo.",
    "If you wish to edit it, change it to unfinished in the schedule":
      "Si deseas editarlo, reactiva el arriendo en el cronograma",
    "If you wish to edit it, reactivate at least one of the equipments leases in the schedule":
      "Si deseas editarlo, reactiva el arriendo de al menos uno de los equipos en el cronograma",
    "If your equipment has a RendaloMaq GPS, here you can check the reading of its Odometer.":
      "Si tu equipo posee un GPS de RendaloMaq, aqui podrás revisar el la lectura de su Odómetro.",
    "If your equipment has a RendaloMaq GPS, here you can check the reading of your Hourmeter.":
      "Si tu equipo posee un GPS de RendaloMaq, aqui podrás revisar el la lectura de su Horómetro.",
    "If your team has a RendaloMaq GPS, here you can check its location in real time.":
      "Si tu equipo posee un GPS de RendaloMaq, aqui podrás revisar su ubicación en tiempo real.",
    Ignition: "Ignición",
    Image: "Image",
    Imei: "Imei",
    "In case they are leased": "En caso de que estén arrendadas",
    "In motion": "En movimiento",
    "In use": "En uso",
    "Inactive machine": "Máquina inactiva",
    "Inactive machines": "Máquinas inactivas",
    "Include machines available for sale":
      "Incluir máquinas disponibles para la venta",
    Includes: "Incluye",
    "Includes field training": "Incluye inducción en terreno",
    "Includes technical support 24h": "Incluye soporte técnico 24h",
    Incomes: "Ingresos",
    Industrial: "Industrial",
    Industry: "Industria",
    "Información del equipo": "Información del equipo",
    Inminent: "Inminente",
    "Inminent singular": "Inminente",
    "Inspection Resume": "Resumen de inspección",
    Instagram: "Instagram",
    "Integrate with google calendar": "Integrar con Google Calendar",
    "Integration with google calendar": "Integración con Google Calendar",
    "Internal ID": "Identificador Interno",
    "Internal id": "Identificador interno",
    "Internal number already exists":
      "Numero de arriendo actualmente en uso, por favor ingrese otro.",
    "Internal use": "Uso interno",
    "Invalid DNI": "Rut invalido, por favor introduzca un rut valido",
    "Invalid reset code": "Código inválido",
    Inventory: "Inventario",
    Invoice: "Factura",
    "Invoice attach": "Adjuntar factura",
    Invoiced: "Facturado",
    "Invoiced by business": "Arriendo por negocio",
    "Invoiced in plural": "Facturadas",
    Invoices: "Facturas",
    "Is active": "Activa",
    "Is email enabled": "Recibe informaciones por email",
    "Is featured": "Destacada",
    "Is public": "Pública",
    "Is whatsapp enabled": "Recibe informaciones por Whatsapp",
    "It includes the points against found during the inspection.":
      "Incluye los puntos en contra hallados durante la inspección.",
    "It includes the points found during the inspection.":
      "Incluye los puntos encontrados durante la inspección.",
    "It includes the points in favor found during the inspection.":
      "Incluye los puntos a favor hallados durante la inspección.",
    "It is no longer visible on the sales site":
      "Ya no es visible en el sitio de ventas.",
    "It is not possible to lease the same machine more than once in the same period of time. Please check all fields and try again":
      "No es posible arrendar una misma máquina más de una vez en un mismo periodo de tiempo. Por favor verifica todos los campos e intenta nuevamente",
    "It will add this machine into the sale web":
      "Se agregará esta máquina al sitio de ventas",
    "It will be send to": "Se enviará a",
    "It will remove this machine from the sale web":
      "Esta máquina no aparecerá en la web de ventas",
    Items: "Items",
    Iva: "IVA",
    January: "Enero",
    July: "Julio",
    June: "Junio",
    Justification: "Justificación",
    Kind: "Tipo",
    "Km price": "Precio extra por kilómetro",
    "Last month": "Último mes",
    "Last name": "Apellido",
    Latam: "Latam",
    "Latam dashboard": "Latam dashboard",
    Latitude: "Latitud",
    Lead: "Requerimiento",
    "Lead detail": "Detalle del requerimiento",
    "Lead machines": "Máquinas del requerimiento",
    Leads: "Leads",
    Lease: "Arriendo",
    "Lease ID": "Id Renta",
    "Lease availability notes": "Lease availability notes",
    "Lease business": "Negocios Renta",
    "Lease days": "Días de arriendo",
    "Lease duration": "Duración del arriendo",
    "Lease equipments": "Equipos en renta (AUM)",
    "Lease machine": "Máquina arrendada",
    "Lease number": "N° arriendo",
    Leased: "Arrendado",
    "Leased by Rendalomaq": "RendaloMaq",
    LeasedByRendalomaq: "RendaloMaq",
    Leases: "Arriendos",
    "Leases ($)": "Arriendos ($)",
    "Leases machines": "Máquinas arrendadas",
    "Leases margin ($)": "Margen de arriendos ($)",
    "Leases net": "Arriendos netos",
    "Leases of the month": "Arriendos del mes",
    "Leases of your machines": "Arriendos de tus máquinas",
    Leasing: "Arriendos",
    "Leasing detail": "Detalle del arriendo",
    "Leasing start": "Inicio de arriendo",
    "Link copied": "Link copiado",
    Linkedin: "Linkedin",
    List: "Listado",
    Loading: "Cargando",
    "Loading...": "Cargando...",
    Location: "Ubicación",
    "Log in": "Iniciar sesión",
    "Log out": "Cerrar sesión",
    Longitude: "Longitud",
    Lost: "Perdido",
    "Lost rate": "Tasa de perdida",
    "Machine 2": "Machine 2",
    "Machine 3": "Machine 3",
    "Machine ID": "ID Equipo",
    "Machine Kind": "Machine Kind",
    "Machine information": "Información de la máquina",
    "Machine price": "Precio de máquina",
    Machinery: "Maquinaria",
    Machines: "Máquinas",
    "Machines to be delivered": "Máquinas por entregar",
    "Machines to retrieve": "Máquinas por retirar",
    "Machines you want to add in the extension":
      "Máquinas que desea agregar en la extensión",
    Main: "Principal",
    Maintenance: "Mantenimiento",
    "Make budget": "Generar cotización",
    Management: "Gerencial",
    "Management dashboard": "Management dashboard",
    Maneuver: "Maniobras",
    Maneuvers: "Maneuvers",
    Map: "Mapa",
    March: "Marzo",
    "Margin by business": "Margen por negocio",
    Margins: "Márgenes",
    "Mark as accepted": "Marcar como aceptada",
    "Mark as paid": "Marcar como pagada",
    "Mark as pending": "Marcar como pendiente",
    May: "Mayo",
    Meeting: "Reunión",
    Mexico: "México",
    "Minimum hours": "Horas mínimas de uso",
    Model: "Modelo",
    Month: "Mes",
    Monthly: "Mensual",
    "Monthly price": "Precio mensual",
    "Most leased machine": "Máquina más arrendada",
    Movement: "Movimiento",
    "Movement already delivered":
      "La maquina ya posee un movimiento de envio creado",
    "Movement saved": "Movimiento guardado",
    "Movement with same kind and machine already exists":
      "La maquina ya posee un movimiento del mismo tipo creado",
    Movements: "Movimientos",
    Movimiento: "Movimiento",
    Municipality: "Municipio",
    "Must be only one budget detail with category_id":
      "Ya hay una máquina asociada a este período, verifíquela e inténtelo nuevamente",
    "Must be only one lead detail in the lead":
      "El período ya fue seleccionado, verifíquelo e inténtelo nuevamente.",
    "Must be only one purchase order detail with category_id":
      "Ya hay una máquina asociada a este período, verifíquela e inténtelo nuevamente",
    "Must be only one supplier by lead detail in the same lead":
      "Para esta maquina ya se ha seleccionado un proveedor de arriendo distinto.",
    "Must not be lead details with different months by budget":
      "Must not be lead details with different months by budget",
    "My Space": "Mi espacio",
    NAME: "NOMBRE",
    Name: "Nombre",
    "Name & internal id": "Nombre y número interno",
    "Name and category fields are required":
      "Name and category fields are required",
    "Name is required": "Name is required",
    Negative: "Negativo",
    "Net Billing": "Facturación neta",
    "Net Margin": "Margen neto",
    "Net Margin %": "Margen neto (%)",
    "Net Margin ($)": "Margen neto ($)",
    "Net amount": "Monto neto",
    "Net margin": "Margen neto",
    "Net margin leases": "Margen Rentas",
    "Net margin sales": "Margen Ventas",
    Neutral: "Neutral",
    New: "Nuevo",
    "New event": "Nuevo evento",
    "New lease": "Nuevo arriendo",
    "New machine": "New machine",
    "New rental businesses": "Nuevos negocios de arriendo",
    "New requirement": "Nuevo requerimiento",
    "New sales businesses": "Nuevos negocios de ventas",
    Next: "Siguiente",
    "Next plural": "Próximas",
    "Next rent": "Próximo arriendo",
    "Next week": "Próxima semana",
    No: "No",
    "No events today": "No hay eventos para este día",
    "No items found": "No se encontraron resultados",
    "No records found": "No es encontraron registros",
    "No tasks": "Sin tareas",
    "Non operability": "No operativa",
    "Not Available": "No disponible",
    "Not all the fields are required, but they improve the quality of the information you get later.":
      "No todos los campos son necesarios, pero mejoran la calidad de la información que obtienes posteriormente.",
    "Not available": "No disponible",
    "Not rented": "Not rented",
    "Not sale": "Not sale",
    Notes: "Notas",
    Notifications: "Notificaciones",
    November: "Noviembre",
    "Now you can create leases, movements and change its status as you need":
      "Ahora puedes crear arriendos, movimientos y cambiar su estado cuando sea necesario",
    Number: "Número",
    "Number invoice": "Number invoice",
    "Number of days it takes to deliver the machine":
      "Cantidad de días de anticipación necesarios para ofrecer reserva de la máquina",
    "N°": "N°",
    "N° invoice": "N° Factura",
    "Número de arriendos del mes y la comparación con el mes anterior":
      "Número de arriendos del mes y la comparación con el mes anterior",
    "Número de máquinas de la flota y su estado según disponibilidad":
      "Número de máquinas de la flota y su estado según disponibilidad",
    October: "Octubre",
    Odometer: "Odometro",
    Offer: "Oferta",
    Ok: "Ok",
    "Only edit the data related to this machine":
      "Solo modificar datos relacionados a esta máquina",
    "Only paid ones": "Pagados",
    "Only rent": "Solo renta",
    "Only sell": "Solo venta",
    "Only unpaid ones": "No pagados",
    Open: "Abierto",
    "Open in new tab": "Abrir en una nueva pestaña",
    Operability: "Operatividad",
    Operating: "Operativa",
    Operativity: "Operativity",
    Operator: "Operator",
    "Optimo > 70% | Regular > 40% | Deficiente < 40%":
      "Registrado: % de maquinas con arriendos registrados. Teórico: % de maquinas arrendadas o fuera de area.",
    Optional: "Opcional",
    "Orden de compra": "Orden de compra",
    Order: "Order",
    "Order no.": "N° de orden",
    "Organization DNI": "Rut empresa",
    "Organization already exists":
      "Nombre o rut de la organización existente, por favor pruebe con otro e intente nuevamente.",
    "Organization name": "Empresa",
    Organizations: "Organizaciones",
    Origin: "Origen",
    Other: "Otro",
    "Other documents": "Otros documentos",
    Others: "Otros",
    "Otherwise, you can": "De lo contrario, puedes",
    "Otherwise, you can mark them as": "de lo contrario, puedes marcarlas como",
    "Out of range": "Fuera de rango",
    "Ovservaciones del Técnico": "Ovservaciones del Técnico",
    PDF: "PDF",
    PHONE: "TELÉFONO",
    Paid: "Pagada",
    Partial: "Parcial",
    Password: "Contraseña",
    "Passwords do not match": "Las contraseñas no coinciden",
    Patent: "Patente",
    "Pause automatic actualization": "Pausar actualización automática",
    Payment: "Pago",
    "Payment method": "Metodo de pago",
    Payments: "Pagos",
    Pending: "Pendiente",
    "Pending / Contacted": "Pendientes / Contactados",
    "Pending assignments": "Tareas pendientes",
    "Pending tasks": "Tareas pendientes",
    "Per day": "Por día",
    "Per hour": "Por hora",
    Performance: "Desempeño",
    Period: "Período",
    "Period added successfully": "Período creado exitosamente",
    "Period updated successfully": "Período actualizado exitosamente",
    "Periodos de renta": "Periodos de renta",
    "Periods and Products": "Periodos y Equipos",
    "Person contact": "Persona de contacto",
    "Person who receives": "Persona que recibe",
    Phone: "Teléfono",
    "Phone and email": "Email y teléfono",
    Photo: "Foto",
    Photographs: "Fotografias",
    Photos: "Fotos",
    Pickup: "Retiro",
    Plan: "Cronograma",
    "Please add at least one item": "Por favor, agrega al menos un elemento",
    "Please select a city and state":
      "Por favor seleccione una ciudad y comuna",
    "Please select a product": "Por favor, selecciona un producto",
    "Please select at least one machine":
      "Por favor, seleccione al menos una máquina",
    "Please try again with different filters":
      "Por favor, intente ajustar las fechas de búsqueda e inténtelo nuevamente",
    Positive: "Positivo",
    Previous: "Anterior",
    "Previous use": "Uso anterior",
    "Previous week": "Semana anterior",
    "Previous year": "Año anterior",
    Price: "Precio",
    "Price per hour": "Precio por hora",
    "Price type": "Tipo de precio",
    Product: "Producto",
    "Product ID": "Product ID",
    "Product price": "Precio del producto",
    Products: "Products",
    "Products are not available during the selected period":
      "Los productos no están disponibles durante el periodo seleccionado.",
    Profit: "Ganancias",
    "Profit margin": "Margen de ganacias",
    "Prom days new lease": "Días promedio rentas nuevas",
    "Prom tax": "Tasa promedio del mes:",
    "Provider price": "Precio proveedor",
    "Publish to sale": "Publicar para venta",
    Published: "Publicar para venta",
    "Purchase invoices": "Facturas de compra",
    "Purchase order": "Orden de compra",
    "Purchase order has invoices":
      "No es posible cambiar el estado de esta orden de compra debido a que tiene facturas asociadas a la misma.",
    "Purchase order has no details": "La order de compra no tiene detalles",
    "Purchase orders": "Órdenes de compra",
    Purchases: "Compras",
    "Purchases commercial control": "Control comercial compras",
    Quantity: "Cantidad",
    "Quantity of days": "Cantidad de días",
    Quotation: "Requerimiento",
    Quote: "Quote",
    Radius: "Radio",
    "Range from which the extra transport price begins to apply":
      "Rango desde el cual el precio extra de transporte empieza a aplicarse",
    Rate: "Tarifa",
    Rates: "Tarifas",
    "ReOpen lease": "Reabrir arriendo",
    Reactivate: "Volver a activar",
    Reason: "Justificación",
    Received: "Recibido",
    "Received Payments": "Cobros",
    Receivers: "Receptor(es)",
    "Recommended supplier": "Proveedor recomendado",
    "Record payment": "Registrar pago",
    "Record refund": "Registrar reembolso",
    "Recover your password": "Recupera tu contraseña",
    Reference: "Referencias",
    Refund: "Reembolso",
    Region: "Region",
    "Region & commune": "Región y comuna",
    Register: "Registro",
    Registered: "Registrada",
    Rejected: "Rejected",
    "Rejected by rendalomaq": "Rechazado por Rendalomaq",
    "Reminder sent": "Recordatorio enviado",
    "Remove commertial date": "Eliminar fecha comercial",
    "Remove item": "Eliminar item",
    "Remove payment": "Eliminar pago",
    "Remove refund": "Eliminar reembolso",
    "Remove the subsequent movements of this machine to delete this one":
      "Remove the subsequent movements of this machine to delete this one",
    RendaloMaq: "RendaloMaq",
    "RendaloMaq scores suppliers based on prices, distance and number of available machines, with the aim of selecting the best supplier for each required machine.":
      "RendaloMaq puntúa a los proveedores basado en precios, distancia y cantidad de maquinas disponibles, con el objetivo de seleccionar el mejor proveedor para cada maquina requerida.",
    "Rent ID": "ID Arriendo",
    "Rent and sell": "Arriendo y venta",
    "Rental history": "Historial de arriendo",
    Rented: "Rentado",
    "Reopen order": "Reabrir orden",
    "Replace machine": "Reemplazar máquina",
    "Reportes por falla": "Reportes por falla",
    Reports: "Reportes",
    Req: "Req",
    "Req ID": "Req ID",
    "Req. Lease": "Req. Renta",
    "Req. Sale": "Req. Venta",
    "Request successfully": "Solicitud exitosa",
    "Request supplier": "Solicitar proveedor",
    "Requirement date": "Fecha del requerimiento",
    "Requirement info": "Datos del requerimiento",
    "Requirement machines": "Máquinas del requerimiento",
    "Requirement status": "Estado del requerimiento",
    "Requirement type": "Tipo de requerimiento",
    Requirements: "Requerimientos",
    "Resp.": "Resp.",
    Responsible: "Responsable",
    Responsibles: "Responsables",
    Results: "Resultados",
    Retention: "Retención",
    "Retirement date": "Fecha de retiro",
    Returned: "Devuelto",
    Review: "Revisión",
    Rut: "Rut",
    Sale: "Venta",
    "Sale and lease": "Venta y arriendo",
    "Sale business": "Negocios Venta",
    "Sale information": "Información de venta",
    "Sale price": "Precio de venta",
    Sales: "Ventas",
    "Sales ($)": "Ventas ($)",
    "Sales commercial control": "Control comercial ventas",
    "Sales invoices": "Facturas de venta",
    "Sales margin ($)": "Margen de ventas ($)",
    "Sales net": "Ventas netas",
    "Sales payments": "Pagos de ventas",
    Save: "Guardar",
    "Save and CREATE purchase order": "Guardar y CREAR orden de compra",
    "Save and SEND purchase order": "Guardar y ENVIAR orden de compra",
    "Save and create budget": "Guardar y crear cotización",
    "Save and create lease": "Guardar y crear arriendo",
    "Save and create purchase order": "Guardar y crear orden de compra",
    "Save and send": "Guardar y enviar",
    "Save and send budget": "Guardar y enviar cotización",
    "Save and send purchase order": "Guardar y enviar orden de compra",
    "Save budget": "Guardar cotización",
    "Save draft": "Guardar borrador",
    "Save purchase order": "Guardar orden de compra",
    Schedule: "Programar",
    Schedule_client: "Cliente",
    "Se creará una nueva cotización en base al formulario y se almacenará en nuestra DB, la cual podrás descargar.":
      "Se creará una nueva cotización en base al formulario y se almacenará en nuestra DB, la cual podrás descargar.",
    "Se creará una nueva cotización en base al formulario, se almacenará  en nuestra DB y se enviará automáticamente al correo del cliente.":
      "Se creará una nueva cotización en base al formulario, se almacenará  en nuestra DB y se enviará automáticamente al correo del cliente.",
    "Se creará una nueva orden de compra en base al formulario, se almacenará en nuestra DB y se enviará automáticamente al correo del proveedor.":
      "Se creará una nueva orden de compra en base al formulario, se almacenará en nuestra DB y se enviará automáticamente al correo del proveedor.",
    "Se creará una nueva órden de compra en base al formulario y se almacenará en nuestra DB, la cual podrás descargar.":
      "Se creará una nueva órden de compra en base al formulario y se almacenará en nuestra DB, la cual podrás descargar.",
    "Se esta generando el contenido": "Se esta generando el contenido",
    "Se guardará el formulario tal cual como lo hayas dejado.":
      "Se guardará el formulario tal cual como lo hayas dejado.",
    Search: "Buscar",
    "Search by ID Req, payment ID or amount…":
      "Buscar por ID Req, ID de pago o monto...",
    "Search by client...": "Buscar por cliente...",
    "Search by ids or supplier name":
      "Busca por IDs o nombre de cliente o proveedor",
    "Search by name": "Busca por nombre",
    "Search by name, bussiness name or dni…":
      "Busca por nombre, razón social o rut...",
    "Search by name, code or serial number":
      "Buscar por nombre, código o número de serie",
    "Search by name, email or phone": "Buscar por nombre, email o teléfono",
    See: "Ver",
    "See attachment": "Ver adjunto",
    "See branches": "Ver sucursales",
    "See document": "Ver documento",
    "See kanban": "Ver kanban",
    "See lead detail": "Ver requerimiento",
    Select: "Seleccione",
    "Select a category and a product, to show more information about it":
      "Selecciona una categoría y un producto, para mostrar más información respecto a la misma",
    "Select a document (JPG, PNG, PDF)":
      "Selecciona un documento (JPG, PNG, PDF)",
    "Select a invoice (JPG, PNG, PDF)":
      "Selecciona una factura (JPG, PNG, PDF)",
    "Select brand": "Seleccione marca",
    "Select category": "Seleccione categoría",
    "Select date range": "Seleccionar rango de fechas",
    "Select kind": "Seleccione tipo",
    "Select machine": "Seleccionar maquina",
    "Select model": "Seleccione modelo",
    "Select organization": "Seleccionar organización",
    "Select photographs": "Seleccionar fotografías",
    "Select provider": "Seleccionar proveedor",
    "Select provider and machine": "Selecciona proveedor y maquina",
    "Select the machines to lease": "Selecciona las máquinas a arrendar",
    "Select transport provider": "Seleccionar proveedor de transporte",
    "Select view": "Seleccionar vista",
    "Select...": "Seleccione...",
    "Selected machines ({0})": ["Máquinas seleccionadas (", ["0"], ")"],
    Sell: "Venta",
    "Sell information": "Información de venta",
    "Sell price": "Precio de venta",
    Send: "Enviar",
    "Send budget": "Enviar cotización",
    "Send buy order": "Enviar orden de compra",
    "Send invoice": "Enviar factura",
    "Send invoice reminder": "Enviar recordatorio de factura pendiente",
    "Send payment reminder": "Enviar recordatorio de pago",
    "Send purchase order": "Enviar órden de compra",
    "Send quotation": "Enviar cotización",
    "Send reminder": "Enviar recordatorio",
    "Send to": "Enviar a",
    "Sent!": "¡Enviado!",
    September: "Septiembre",
    Serial: "Serial",
    "Serial number": "N° de serie",
    Serie: "Serie",
    "Service area": "Área de Servicio",
    Share: "Compartir",
    "Share all machines": "Compartir todas las máquinas",
    "Share map": "Compartir mapa",
    "Share with": "Compartir con",
    "Shared machines": "Máquinas compartidas",
    Sheet: "Folio",
    "Shipping Address": "Shipping Address",
    "Shipping machines": "Envío de máquinas",
    Show: "Mostrar",
    "Show Less": "Mostrar menos",
    "Show all": "Mostrar todos",
    "Show archived": "Mostrar archivados",
    "Show completed": "Mostrar completadas",
    "Show document": "Mostrar documento",
    "Show machine in map": "Mostrar máquina en el mapa",
    "Show machine on map": "Show machine on map",
    "Show more": "Mostrar más",
    "Show street": "Mostrar calle",
    Showing: "Mostrando",
    "Simplified lease": "Arriendo simplificado",
    "Something went wrong": "Algo salió mal",
    "Sorry, we could dont find the machine data.":
      "No pudimos encontrar los datos de la máquina.",
    "Sorry, we have not found suggestions that match the search criteria":
      "Lo sentimos, no hemos encontrado sugerencias que coincidan con los criterios de búsqueda",
    "Specific Sale": "Venta específica",
    Specifications: "Especificaciones",
    Speed: "Velocidad",
    Staff: "Personal encargado",
    Start: "Inicio",
    "Start automatic actualization": "Iniciar actualización automática",
    "Start date": "Fecha de inicio",
    "Start date must be before end date":
      "La fecha de inicio debe ser menor a la fecha de finalización.",
    "Start searching": "Iniciar búsqueda automática",
    State: "Región",
    "State Justification": "Justificación del estado",
    "State of delivery": "Estado de entrega",
    "State of the machines": "Estado de las máquinas",
    "State of the machines today": "Estado de las máquinas hoy",
    Status: "Estado",
    Step: "Paso",
    "Stop sharing": "Dejar de compartir",
    Street: "Calle",
    "Street Number": "Numero de calle",
    "Street address": "Dirección calle",
    SubCategories: "SubCategories",
    Subtotal: "Subtotal",
    "Successful request": "Solicitud exitosa",
    Summary: "Resumen",
    Supplier: "Proveedor",
    "Supplier has no branch": "El proveedor no posee sucursal",
    "Supplier internal id already exists":
      "Supplier internal id en uso o máquina existente, verifique la información e intente nuevamente",
    "Supplier manual set is only available when the automatic supplier request is not active":
      "La selección manual del proveedor y el precio no están disponibles mientras la búsqueda de proveedor para esta máquina esté activa",
    "Supplier price": "Precio proveedor",
    "Supplier request history": "Historial de búsqueda de proveedor",
    "Supplier search": "Búsqueda de proveedor",
    "Supplier suggestion": "Recomendaciones de proveedor",
    "Supplier user already exists": "Este usuario ya existe",
    "Supplier users": "Usuarios",
    Suppliers: "Proveedores",
    "Suppliers fee": "Tarifas de proveedores",
    Tags: "Tags",
    "Take rate": "Take rate",
    "Tasa de ocupación promedio de tu flota en meses anteriores":
      "Tasa de ocupación promedio de tu flota en meses anteriores",
    Task: "Tarea",
    Tasks: "Tareas",
    "Tasks list": "Lista de tareas",
    Tax: "IVA",
    "Tax discount": "Retención",
    "Technical Service": "Servicio Técnico",
    "Technical conclusions": "Conclusiones del técnico",
    "Technical issues": "Technical issues",
    "Technical remarks": "Observaciones del técnico",
    "Technical support 24h": "Soporte técnico 24h",
    "Terms and conditions": "Términos y condiciones",
    "The base period already exists": "Ya existe un periodo base",
    "The binnacle is empty": "La bitácora está vacía",
    "The budget has been accepted and cannot be deleted":
      "El presupuesto ha sido aceptado y no se puede eliminar.",
    "The budget has been sent to the customer":
      "El presupuesto ha sido enviado al cliente exitosamente.",
    "The budget has movements and cannot be deleted":
      "El requerimiento tiene movimientos y no se puede eliminar",
    "The client does not need this machine anymore":
      "El cliente ya no necesita la máquina",
    "The content autamatically generated will be published in the sale web":
      "The content autamatically generated will be published in the sale web",
    "The content automatically generated will be published in the sale web":
      "The content automatically generated will be published in the sale web",
    "The content generated will be published in the sale web":
      "El contenido generado será publicado en la web de venta",
    "The content is being generated": "El contenido se está generando",
    "The following errors were found":
      "Los siguientes errores fueron encontrados",
    "The form will be saved as you left it.":
      "El formulario se guardará como lo dejó.",
    "The info displayed is based in net amounts":
      "La información reflejada está basada en montos netos",
    "The information reflected corresponds to the average lease ticket":
      "La información reflejada corresponde al ticket de arriendo promedio",
    "The information reflected corresponds to the average sale ticket":
      "La información reflejada corresponde al ticket promedio de ventas",
    "The information reflected corresponds to the billing":
      "La información reflejada corresponde a la facturación",
    "The item has been deleted": "El elemento ha sido eliminado",
    "The last person that edited this lead was:":
      "La última persona en editar este requerimiento fue:",
    "The lead has already been finished":
      "No puede realizar esta acción porque el estatus de la máquina esta finalizado, por favor verifique el estado en el cronograma y vuelva a intentarlo.",
    "The lead has no base period, please contact the administrator to fix it.":
      "El requerimiento no tiene período base, por favor contacte al administrador para arreglarlo.",
    "The lead has no details":
      "El requerimiento no tiene períodos ni equipos asociados",
    "The lead must be active": "El lead debe estar activo",
    "The lead must be confirmed": "El lead debe estar confirmado",
    "The lead will appear again on the kanban and will be editable":
      "El requerimiento aparecerá nuevamente en el kanban y será editable",
    "The lead will no longer appear on kanban view and will not be editable":
      "El requerimiento desaparecerá de la vista del kanban y no será editable",
    "The lease for this equipment is finished":
      "El arriendo para este equipo está finalizado",
    "The machine doesnt have periods": "El item seleccionado no tiene periodos",
    "The machine has no accepted budgets":
      "La maquina no se encuentra en ninguna cotizacion aceptada",
    "The machine should have a supplier":
      "La maquina seleccionada no tiene proveedor asignado",
    "The machine suffered a defect which prevents its correct operation":
      "La máquina sufrió algún desperfecto el cual impide su correcto funcionamiento",
    "The machines have been shared":
      "La geolocalización de las máquinas se ha compartido",
    "The period cannot be deleted because it has budgets":
      "The period cannot be deleted because it has budgets",
    "The period cannot be deleted because it has budgets or purchase orders associated":
      "El periodo no se puede eliminar porque tiene cotizaciones u órdenes de compra asociados",
    "The period cannot be updated because it has budgets associated":
      "El periodo no se puede actualizar porque tiene cotizaciones asociadas",
    "The period cannot be updated because it has budgets or purchase orders associated":
      "El periodo no se puede actualizar porque tiene cotizaciones u órdenes de compra asociados",
    "The period cannot be updated because it has purchase orders associated":
      "El periodo no se puede actualizar porque tiene órdenes de compra asociadas",
    "The period dates collide with another period":
      "Las fechas del período actual colisionan con otro período.",
    "The same driver will handle the delivery and withdrawal of this machine.":
      "El mismo conductor se encargará de la entrega y retiro de esta maquinaria",
    "The search ended in just": "The search ended in just",
    "The selected model does not have an associated product, please select a category and product":
      "El modelo seleccionado no tiene un categoria asociada, por favor seleccione una categoría y producto",
    "The technical service has been saved":
      "El servicio técnico ha sido guardado",
    Theoretical: "Teórica",
    "There are no events that fit your filters. Change the parameters or create a new lease to see it in the Gantt":
      "No hay eventos que se ajusten a tus filtros. Cambie los parámetros o cree un nuevo arrendamiento para verlo en la Gantt.",
    "There are no inactive machines in this lease yet.":
      "Este arriendo aún no tiene máquinas inactivas",
    "There are no machines that fit your filters. Change the parameters or add a GPS to one of your machines to see it in the map":
      "No hay máquinas que se ajusten a tus filtros. Cambia los parámetros o añade un GPS a alguna de tus máquinas para visualizarla en el mapa",
    "There are no orders associated to this machine.":
      "No hay arriendos asociados a esta máquina",
    "There is a supplier already setted":
      "Ya existe un proveedor asignado para esta máquina",
    "There is already a fee with this product and location":
      "Ya existe una tarifa para este producto y localización",
    "There is no address associated": "No hay dirección asociada",
    "There is no data related to that machine/s":
      "No hay datos relacionados con esa/s máquina/s",
    "There should be no lead details with different months per budget.":
      "No debe haber periodos con diferentes meses por cotizacion.",
    "There were an error in your submission":
      "Hubo un error al enviar tu solicitud",
    "There were an error in your submission. Please validate all fields and try again.":
      "Ocurrió un error en tu solicitud. Por favor valida todos los campos e intenta de nuevo.",
    "Theres not a goal setted or matching with the current filters setted":
      "No hay una meta que coincida con los filtros seteados",
    "Theres not enough info to render this graph, you can try changing the mix of the filters.":
      "No hay suficiente información para mostrar este gráfico, intenta cambiar los filtros seleccionados.",
    "These fields generate a condition, which evaluates whether or not a requirement is eligible for an automatic quote.":
      "Estos campos generan una condición, que evalúa si un requisito es elegible o no para una cotización automática.",
    "They are the photos that are seen in the detail of the machine.":
      "Son las fotos que se ven en el detalle de la máquina.",
    "This budget already has an associated credit note":
      "Esta cotización ya tiene una nota de crédito asociada",
    "This budget has credit notes associated":
      "Esta cotización tiene notas de crédito asociadas",
    "This lead is archived": "Este requerimiento está archivado",
    "This lease ended": "Este arriendo finalizó",
    "This lease is closed.": "El arriendo se encuentra cerrado.",
    "This lease is finished": "Este arriendo ha finalizado",
    "This machine corresponds to the current rental period.":
      "Esta máquina corresponde al periodo actual de arriendo.",
    "This machine has been decommissioned": "Esta máquina ha sido dada de baja",
    "This machine has been published": "Esta máquina ha sido publicada",
    "This machine has no GPS assigned to it":
      "Esta máquina no tiene un GPS asignado",
    "This machine has no GPS assigned to it and no counters to display":
      "Esta máquina no tiene un GPS asignado ni contadores para mostrar.",
    "This machine has no images associated to it":
      "Esta máquina no tiene imágenes asociadas",
    "This machine was replaced": "Esta máquina fue reemplazada",
    "This process can take a few minutes, once it is ready it will be automatically reflected on the sales page of this machine.":
      "Este proceso puede durar unos minutos, una vez listo este se verá reflejado automáticamente en la página de venta de esta máquina.",
    "This purchase order already has an associated credit note":
      "Esta orden de compra ya tiene una nota de crédito asociada",
    "This purchase order has credit notes associated":
      "Esta orden de compra tiene notas de crédito asociadas",
    "This requirement has no documents added, you can add one through the actions button.":
      "Este requerimiento no tiene documentos asociados, puedes agregar uno desde el botón de acciones",
    "This requirement has no items, you can add one through the actions button.":
      "Este requerimiento no tiene máquinas asociadas, puedes agregar una desde el botón de acciones",
    Threshold: "Umbral",
    "Ticket leases": "Ticket de arriendo",
    "Ticket sales": "Ticket de venta",
    Time: "Hora",
    "Time of use": "Tiempo de uso",
    Title: "Título",
    To: "Hasta",
    "To add a": "Para agregar una",
    "To collect": "Por cobrar",
    "To confirm budget": "Cotización por confirmar",
    "To confirm purchase order": "Órden de compra por confirmar",
    "To deliver": "A retirar",
    "To edit it you must unarchive it first":
      "Para editarlo debes desarchivarlo primero",
    "To edit or/add periods you will need to":
      "Para editarlo/añadir periodos tendrás que",
    "To expire": "Por vencer",
    "To facilitate the search of your machine in the system":
      "Para facilitar la búsqueda de tu máquina en el sistema",
    "To integrate with google calendar, you must follow the following steps:":
      "Para integrar su agenda con Google Calendar siga estos pasos:",
    "To invoice": "Por facturar",
    "To pay": "Por pagar",
    "To rent": "Para arriendo",
    "To select": "Seleccionar",
    "To sell": "Para venta",
    "To sell or rent": "Para arriendo o venta",
    "To which the machines will be destined.":
      "A la cual será destinada la maquinaria",
    "To widthdraw": "A retirar",
    Today: "Hoy",
    "Top machines": "Top máquinas",
    Total: "Total",
    "Total ($)": "Total ($)",
    "Total amount": "Monto total",
    "Total and percentage global": "Total y porcentaje global",
    "Total billing": "Facturación total",
    "Total delivery": "Máquinas entregadas",
    "Total global": "Total global",
    "Total horometer": "Total horómetro",
    "Total income": "Ingreso total",
    "Total leases": "Arriendos totales",
    "Total machines": "Máquinas totales",
    "Total margin": "Margen total",
    "Total margin percent": "Margen total (%)",
    "Total net": "Total neto",
    "Total odometer": "Total odómetro",
    "Total price": "Precio total",
    Tracking: "Seguimiento",
    Transport: "Transporte",
    "Transport price": "Precio de transporte",
    "Transport provider": "Proveedor de transporte",
    "Transport provider price": "Precio proveedor de transporte",
    "Transport supplier": "Proveedor de transporte",
    Transportation: "Transportation",
    "Transportation price": "Precio base de traansporte",
    "Traveled distance": "Distancia recorrida",
    Twitter: "Twitter",
    Type: "Tipo",
    "Ult. Act": "Ult. Act",
    "Unable to log in with provided credentials.":
      "No pudimos autenticar con estas credenciales.",
    "Unarchive lead": "Desarchivar requerimiento",
    Undefined: "Indefinido",
    Undone: "No realizadas",
    "Unexpected error": "Error inesperado",
    "Unit value": "Valor unitario",
    Unity: "Unidad",
    Unknow: "Unknow",
    Unknown: "Unknown",
    "Unknown error": "Error inesperado",
    Unsubscribe: "Dar de baja",
    Unsubscribed: "Dar de baja",
    Update: "Actualizar",
    "Updated at": "Actualizado al",
    Upload: "Subir",
    "Upload a (or some) file(s)": "Sube uno (o varios) archivo(s)",
    "Upload a (or some) photo(s)": "Sube una (o varias) foto(s)",
    "Upload document": "Subir documento",
    "Upload invoice": "Subir factura",
    "Usage report": "Informe de uso",
    Use: "Uso",
    Used: "Usado",
    Valid: "Válido",
    View: "Ver",
    "We are": "¡Estamos a",
    "We are currently working on some server updates":
      "Estamos trabajando en actualizar nuestros servidores",
    "We are working to find the problem":
      "Estamos trabajando para encontrar el problema",
    "We have not found any documents uploaded to the system.":
      "No hemos encontrado documentos cargados en el sistema.",
    "We have not found invoices uploaded in the system.":
      "No hemos encontrado facturas cargadas en el sistema.",
    Web: "Web",
    Week: "Semana",
    Weekly: "Semanal",
    "Weekly price": "Precio semanal",
    Welcome: "Bienvenido",
    "What the customer want": "El cliente está buscando...",
    Whatsapp: "Whatsapp",
    "With commercial date": "Con fecha comercial",
    "With tasks": "Con tareas",
    Withdrawal: "Retiro",
    "Withdrawal date": "Fecha de retiro",
    "Withdrawal driver": "Conductor de retiro",
    "Without commercial date": "Sin fecha comercial",
    Won: "Ganado",
    "Won Leads": "Negocios ganados",
    "Work was not carried out": "La obra no se llevó a cabo",
    "Write a note": "Escribir una nota",
    Year: "Año",
    Yes: "Si",
    "Yes, Im sure": "Si, estoy seguro",
    "You are currently viewing a filtered view of the map":
      "Actualmente estás viendo una versión filtrada del mapa",
    "You can leave a record of what happened with the request":
      "Puedes dejar un registro de lo que sucedió con este requerimiento",
    "You can leave a record of what happened with the request, and tag a colleague with the @":
      "Puedes dejar un registro de lo que sucedió con este requerimiento, y mencionar a alguien con @",
    "You can see its content on the sales site":
      "Puedes ver su contenido en el sitio de ventas.",
    "You don't have any delivery pending schedules":
      "No tienes entregas pendientes agendadas",
    "You don't have any expired schedules": "No tienes maquinas vencidas",
    "You don't have any inminent requirements":
      "No tienes requerimientos inminentes en este momento",
    "You don't have any pending / contacted requirements":
      "No tienes requerimientos pendientes / contactados en estos momentos",
    "You don't have any schedules to expire": "No tienes maquinas por vencer",
    "You have no notifications at the moment":
      "En este momento no tienes notificaciones",
    "You have no pending tasks": "No tienes tareas pendientes",
    "You have no upcoming tasks": "No tienes tareas pendientes",
    'You have {0} machines "out of range".': [
      "Tienes ",
      ["0"],
      " máquinas con estado “Fuera de Rango”.",
    ],
    'You have {0} machines "out of range". If they are leased,': [
      "Tienes ",
      ["0"],
      " máquinas con estado “Fuera de rango”. En caso de que estén arrendadas,",
    ],
    'You have {0} machines <0>"Out of range";</0>': [
      "Tienes ",
      ["0"],
      " máquinas con estado <0>“Fuera de Rango”</0>;",
    ],
    'You have {0} machines <0>"out of range"</0>;': [
      "You have ",
      ["0"],
      ' machines <0>"out of range"</0>;',
    ],
    'You have {0} machines with status "out of range", you need': [
      "Tienes ",
      ["0"],
      " máquinas con estado “Fuera de rango”, necesitas",
    ],
    "You must accept a budget": "Debes aceptar un presupuesto.",
    "You must accept a budget and the lead status must be active":
      "Debes aceptar un presupuesto y el estado del cliente potencial debe estar activo.",
    "You must add a customer to every lease":
      "Debes añadir una persona contacto a cada arriendo",
    "You must add a supplier to create or send a purchase order":
      "Debe agregar un proveedor para crear o enviar una orden de compra",
    "You must add at least one machine to every lease":
      "Debes añadir al menos una máquina a cada arriendo",
    "You must add the supplier price to create or send a purchase order":
      "Debes ingresar el precio del proveedor para crear o enviar una orden de compra.",
    "You must select a machine":
      "Debes seleccionar una maquina para poder crear un movimiento",
    "You must upload at least one image": "Debes añadir al menos una imagen",
    "You should add a start date for the all machines before add a new one.":
      "Se deben definir las fechas de todas las maquinas antes de agregar una nueva.",
    "You will add a purchase order to the requirement which you can download and/or send to the customer.":
      "Agregaras una orden de compra al requerimiento la cual podrás descargar y/o enviar al cliente.",
    "You will add a quote to the request which you can download and/or send to the client.":
      "Agregaras una cotización al requerimiento la cual podrás descargar y/o enviar al cliente.",
    "Your equipment has been added": "Tu equipo se ha añadido",
    "Your equipment has been saved": "Tu equipo se ha guardado",
    "Your item has been sent": "El elemento ha sido enviado",
    "Your reminder has been sent successfully":
      "Su recordatorio ha sido enviado exitosamente",
    "Your request has been successful": "Su solicitud ha sido exitosa",
    "Your request has been successfully": "Su solicitud ha sido exitosa",
    _Client: "Cliente",
    "ahead of our goal for": "adelante de nuestra meta para el periodo",
    "applied the discount coupon:": "aplicó el cupón de descuento:",
    "as unavailable": "como “No disponible”",
    "away from reaching our current goal!": "de alcanzar nuestra meta actual!",
    "away from reaching the goal for":
      "de alcanzar nuestra meta para el periodo",
    better: "mejor",
    "better than last month": "mejor respecto al mes pasado",
    box_time: "Hora",
    budget: "Cotización",
    buy_order: "Orden de compra",
    check: "Cheque",
    "close rate": "close rate",
    "create the corresponding leases.": "crea los arriendos.",
    credit_card: "Tarjeta de Crédito",
    day: "día",
    days: "días",
    debit_card: "Tarjeta de Débito",
    deficient: "deficiente",
    delivery_guide: "Guía de despacho",
    equal: "se mantiene",
    "equal to previous month": "Igual que el mes anterior",
    filter_action: "Filtrar",
    "for increase your occupancy rate.": "para aumentar tu tasa de ocupación.",
    hours: "horas",
    inminent_plural: "Inminentes",
    inminent_singular: "Inminente",
    kilometers: "kilómetros",
    last_update: "Ult. Act",
    lease: "arriendo",
    leases: "arriendos",
    less: "menos",
    "less than the previous month": "menos que el mes anterior",
    machines: "Máquinas",
    "mark them": "marcarlas",
    month: "mes",
    months: "meses",
    more: "más",
    "more than the previous month": "más que el mes anterior",
    needs: "necesita",
    "needs:": "needs:",
    of: "de",
    optimum: "óptimo",
    optional: "optional",
    other: "Documentos varios",
    payment: "Comprobante de pago",
    "percent margin leases": "Margen rentas (%)",
    "percent margin sales": "Margen ventas (%)",
    periods: "periodos",
    "previous month": "mes anterior",
    "purchase order": "orden de compra",
    "purchaseOrder creation": "Creación de orden de compra",
    "reactivate it": "volver a activarlo",
    regular: "regular",
    "rendalo-title": "ROS",
    sale: "venta",
    status_lead: "Estado",
    supplier_invoice: "Facturas de proveedores",
    terms_values:
      "Pago al contado.\nFirmar contrato de arriendo.\nDocumento en garantía $5.000.000.- (cheque).",
    "the previous month": "el mes anterior",
    "this machine has been decommissioned": "Esta máquina ha sido dada de baja",
    "this month": "este mes",
    "this product": "this product",
    "to increase your occupancy rate.": "para aumentar tu tasa de ocupación.",
    "to the previous month": "al mes anterior",
    transfert: "Transferencia Electrónica",
    unavailable: "no disponible",
    undefined: "Indefinido",
    "up to": "hasta",
    week: "semana",
    weeks: "semanas",
    "will receive an email with the instructions":
      "recibirá un correo con las instrucciones",
    worse: "peor",
    "worst than last month": "menos respecto al mes anterior",
    "you must select a requirement already created":
      "debes seleccionar un requerimiento ya creado",
    "your@email.com": "your@email.com",
    "{0}": [["0"]],
  },
};
