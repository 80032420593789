import { get } from "lodash";
import { API_HOST } from "../client/config";
import { dashboardFilters } from "../dto/management";
import { inventoryApi } from "./inventory";
import { DashboardLatamData } from "../dto/dashboard-latam";

export const dashboardLatamApi = inventoryApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getDashboardLatamFilters: builder.query<dashboardFilters, void | null>({
      query: () => {
        return `${API_HOST}/suppliers/v102/business/dashboard/filters?is_latam=true`;
      },
    }),
    getDashboardLatamLeadsPerDay: builder.query({
      query: ({ filters }) => {
        const params = new URLSearchParams();
        if (filters.month) params.append("month", filters.month);
        if (filters.industry) params.append("industry", filters.industry);
        if (filters.service_area_ids) {
          filters.service_area_ids.forEach((id: string) => {
            params.append("service_area_ids", `${id}`);
          });
        }
        return `${API_HOST}/suppliers/v102/business/dashboard-latam/leads-per-day?${params.toString()}`;
      },
    }),
    getDashboardLatamSalesLeads: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard-latam/on-demand/sales-leads?year=${year}`;
      },
    }),
    getDashboardLatamLeasesLeads: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard-latam/on-demand/leases-leads?year=${year}`;
      },
    }),
    getDashboardLatamLeases: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard-latam/on-demand/leases?year=${year}`;
      },
    }),
    getDashboardLatamSales: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard-latam/on-demand/sales?year=${year}`;
      },
    }),
    getDashboardLatamTotalNet: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard-latam/on-demand/total-net?year=${year}`;
      },
    }),
    getDashboardLatamSalesNet: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard-latam/on-demand/sales-net?year=${year}`;
      },
    }),
    getDashboardLatamLeasesNet: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard-latam/on-demand/leases-net?year=${year}`;
      },
    }),
    getDashboardLatamNetMargin: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard-latam/on-demand/net-margin?year=${year}`;
      },
    }),
    getDashboardLatamNetMarginLeases: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard-latam/on-demand/net-margin-leases?year=${year}`;
      },
    }),
    getDashboardLatamNetMarginSales: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard-latam/on-demand/net-margin-sales?year=${year}`;
      },
    }),
    getDashboardLatamPercentMargin: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard-latam/on-demand/percent-margin?year=${year}`;
      },
    }),
    getDashboardLatamPercentMarginLeases: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard-latam/on-demand/percent-margin-leases?year=${year}`;
      },
    }),
    getDashboardLatamPercentMarginSales: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard-latam/on-demand/percent-margin-sales?year=${year}`;
      },
    }),
    getDashboardLatamCloseRate: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard-latam/on-demand/close-rate?year=${year}`;
      },
    }),
    getEquipments: builder.query<DashboardLatamData, String>({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard-latam/on-demand/equipments?year=${year}`;
      },
    }),
    getLeaseDays: builder.query<DashboardLatamData, String>({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard-latam/on-demand/lease-days?year=${year}`;
      },
    }),
    getLeaseAverageDays: builder.query<DashboardLatamData, String>({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard-latam/on-demand/lease-average-days?year=${year}`;
      },
    }),
  }),
});

export const {
  useGetDashboardLatamFiltersQuery,
  useGetDashboardLatamLeadsPerDayQuery,
  useGetDashboardLatamSalesLeadsQuery,
  useGetDashboardLatamLeasesLeadsQuery,
  useGetDashboardLatamLeasesQuery,
  useGetDashboardLatamSalesQuery,
  useGetDashboardLatamTotalNetQuery,
  useGetDashboardLatamSalesNetQuery,
  useGetDashboardLatamLeasesNetQuery,
  useGetDashboardLatamNetMarginQuery,
  useGetDashboardLatamNetMarginLeasesQuery,
  useGetDashboardLatamNetMarginSalesQuery,
  useGetDashboardLatamPercentMarginQuery,
  useGetDashboardLatamPercentMarginLeasesQuery,
  useGetDashboardLatamPercentMarginSalesQuery,
  useGetDashboardLatamCloseRateQuery,
  useGetEquipmentsQuery,
  useGetLeaseDaysQuery,
  useGetLeaseAverageDaysQuery,
} = dashboardLatamApi;
